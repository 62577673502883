<template>
    <div  class="book-table " id="book">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <!-- <h3>{{ $route.name }}</h3> -->
                    <router-view></router-view>
                </div>
                <div class="col-lg-6">
                    <div class="">
                        <img class="img-style" :src="imageUrl" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "auth",
        data() {
            return {}
        },//end of data
        computed: {
            imageUrl: function () {
                return '/img/'+this.$route.name + '.svg' 
            }
        },

        methods: {},
            mounted() {
        },//end of method
    }
</script>

<style scoped>
    .img-style{
        max-height: 250px;
        display: block;
        margin: auto;
        margin-top: 210px;
    }
    @media only screen and (max-width: 993px){
        .img-style {
            display: none;
        }
    }
</style>